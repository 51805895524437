import type * as Types from '@/types/graphql';

import type { ComputedRef, Ref } from 'vue';
import { useMutation, type UseMutationOptions } from 'vue-query';
import { appFetcher } from '@/api/gatewayClient';
export type PageInteractionUpdateMutationVariables = Types.Exact<{
  pageInteractionUpdateId: Types.Scalars['ID'];
  input: Types.UpdatePageInteractionInput;
}>;

export type PageInteractionUpdateMutationResponse = {
  pageInteractionUpdate?: Types.Maybe<Pick<Types.PageInteraction, 'id' | 'value'>>;
};

export const PageInteractionUpdateDocument = `
    mutation PageInteractionUpdate($pageInteractionUpdateId: ID!, $input: UpdatePageInteractionInput!) {
  pageInteractionUpdate(id: $pageInteractionUpdateId, input: $input) {
    id
    value
  }
}
    `;
export const usePageInteractionUpdateMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<
    PageInteractionUpdateMutationResponse,
    TError,
    PageInteractionUpdateMutationVariables,
    TContext
  >,
) =>
  useMutation<PageInteractionUpdateMutationResponse, TError, PageInteractionUpdateMutationVariables, TContext>(
    ['PageInteractionUpdate'],
    (
      variables:
        | PageInteractionUpdateMutationVariables
        | ComputedRef<PageInteractionUpdateMutationVariables>
        | Ref<PageInteractionUpdateMutationVariables>,
    ) =>
      appFetcher<PageInteractionUpdateMutationResponse, PageInteractionUpdateMutationVariables>(
        PageInteractionUpdateDocument,
        variables,
      )(),
    options,
  );
usePageInteractionUpdateMutation.getKey = () => ['PageInteractionUpdate'];
