import { usePageInteractionCreateMutation } from '@/api/app/mutations/create-interaction-page.generated';
import { usePageInteractionUpdateMutation } from '@/api/app/mutations/update-interaction-page.generated';
import useInteractionStore from '../stores';
import useEditorStore from '@/modules/editor/modules/common/stores/editor';
import type { Interaction } from '../types';
import hash from 'object-hash';
import { emitUpdateSelectOnPageInteraction } from '@/modules/editor/modules/preview/utils/emitToIframe';
import { usePageInteractionPreviewMutation } from '@/api/app/mutations/updatePreviewInteraction.generated';

export const useCRUDInteraction = () => {
  const pageInteractionCreateMutation = usePageInteractionCreateMutation();
  const pageInteractionUpdateMutation = usePageInteractionUpdateMutation();
  const pageInteractionPreview = usePageInteractionPreviewMutation();
  const editorStore = useEditorStore();
  const interactionStore = useInteractionStore();
  const pageId = computed(() => editorStore.getEditingPageId);
  const pageInteractions = computed(() => interactionStore.getPageInteraction);
  const currentInteraction = computed(() => interactionStore.currentInteractionData);
  const hashData = computed(() => interactionStore.getHashData);

  const interactionHasChange = computed(() => {
    const newHash = hash.MD5(pageInteractions.value?.interactionList || {});
    return hashData.value !== newHash;
  });

  const createInteraction = async () => {
    try {
      const data = await pageInteractionCreateMutation.mutateAsync({
        input: {
          value: pageInteractions.value?.interactionList,
          pageID: pageId.value,
        },
      });
      const hashCompare = hash.MD5(pageInteractions.value?.interactionList || {});
      interactionStore.setHashData(hashCompare);
      await pageInteractionPreview.mutateAsync({
        pageInteractionPreviewId: data.pageInteractionCreate?.id || '',
      });

      if (data.pageInteractionCreate?.id) {
        interactionStore.setPageInteractions({
          id: data.pageInteractionCreate?.id,
          interactionList: data.pageInteractionCreate?.value as Interaction[],
        });
        const changedContinue = checkChangeWhenUpdated(hashCompare);
        if (changedContinue) {
          editorStore.setAutoSaveEnable(true);
          return;
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const isExitsCurrentInteraction = computed(() => {
    return pageInteractions.value?.interactionList.some(
      (interaction) => interaction.id === currentInteraction.value?.id,
    );
  });

  const updateInteraction = async () => {
    try {
      if (!pageInteractions.value.id) return;
      const newInteractions = isExitsCurrentInteraction
        ? pageInteractions.value?.interactionList.map((interaction) => {
            return interaction.id === currentInteraction.value?.id ? currentInteraction.value : interaction;
          })
        : pageInteractions.value?.interactionList;
      const data = await pageInteractionUpdateMutation.mutateAsync({
        pageInteractionUpdateId: pageInteractions.value.id,
        input: {
          value: newInteractions,
        },
      });
      if (data.pageInteractionUpdate?.id) {
        const hashCompare = hash.MD5(newInteractions);
        interactionStore.setHashData(hashCompare);
        await pageInteractionPreview.mutateAsync({
          pageInteractionPreviewId: data.pageInteractionUpdate?.id || '',
        });
        const changedContinue = checkChangeWhenUpdated(hashCompare);
        if (changedContinue) {
          editorStore.setAutoSaveEnable(true);
          return;
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const checkChangeWhenUpdated = (hashCompare: string) => {
    const localDataHash = hash.MD5(pageInteractions.value?.interactionList || {});
    return localDataHash !== hashCompare;
  };

  const upsertInteraction = async () => {
    if (!interactionHasChange.value) return;
    if (pageInteractions.value?.id) {
      await updateInteraction();
    } else {
      await createInteraction();
    }
  };

  const addInteraction = (type: 'PAGE' | 'ELEMENT') => {
    interactionStore.addInteraction({
      self: {
        type,
      },
    });
    emitUpdateSelectOnPageInteraction(true, undefined, {
      mode: type ?? 'PAGE',
      settingType: 'TRIGGER',
    });
    interactionStore.setIsSelectOnPageForTrigger(true);
  };

  return {
    upsertInteraction,
    addInteraction,
    interactionHasChange,
  };
};
