import type * as Types from '@/types/graphql';

import type { ComputedRef, Ref } from 'vue';
import { useMutation, type UseMutationOptions } from 'vue-query';
import { appFetcher } from '@/api/gatewayClient';
export type PageInteractionPreviewMutationVariables = Types.Exact<{
  pageInteractionPreviewId: Types.Scalars['ID'];
}>;

export type PageInteractionPreviewMutationResponse = Pick<Types.Mutation, 'pageInteractionPreview'>;

export const PageInteractionPreviewDocument = `
    mutation PageInteractionPreview($pageInteractionPreviewId: ID!) {
  pageInteractionPreview(id: $pageInteractionPreviewId)
}
    `;
export const usePageInteractionPreviewMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<
    PageInteractionPreviewMutationResponse,
    TError,
    PageInteractionPreviewMutationVariables,
    TContext
  >,
) =>
  useMutation<PageInteractionPreviewMutationResponse, TError, PageInteractionPreviewMutationVariables, TContext>(
    ['PageInteractionPreview'],
    (
      variables:
        | PageInteractionPreviewMutationVariables
        | ComputedRef<PageInteractionPreviewMutationVariables>
        | Ref<PageInteractionPreviewMutationVariables>,
    ) =>
      appFetcher<PageInteractionPreviewMutationResponse, PageInteractionPreviewMutationVariables>(
        PageInteractionPreviewDocument,
        variables,
      )(),
    options,
  );
usePageInteractionPreviewMutation.getKey = () => ['PageInteractionPreview'];
