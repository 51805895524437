import type * as Types from '@/types/graphql';

import type { ComputedRef, Ref } from 'vue';
import { useMutation, type UseMutationOptions } from 'vue-query';
import { appFetcher } from '@/api/gatewayClient';
export type PageInteractionCreateMutationVariables = Types.Exact<{
  input: Types.CreatePageInteractionInput;
}>;

export type PageInteractionCreateMutationResponse = {
  pageInteractionCreate?: Types.Maybe<Pick<Types.PageInteraction, 'id' | 'value'>>;
};

export const PageInteractionCreateDocument = `
    mutation PageInteractionCreate($input: CreatePageInteractionInput!) {
  pageInteractionCreate(input: $input) {
    id
    value
  }
}
    `;
export const usePageInteractionCreateMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<
    PageInteractionCreateMutationResponse,
    TError,
    PageInteractionCreateMutationVariables,
    TContext
  >,
) =>
  useMutation<PageInteractionCreateMutationResponse, TError, PageInteractionCreateMutationVariables, TContext>(
    ['PageInteractionCreate'],
    (
      variables:
        | PageInteractionCreateMutationVariables
        | ComputedRef<PageInteractionCreateMutationVariables>
        | Ref<PageInteractionCreateMutationVariables>,
    ) =>
      appFetcher<PageInteractionCreateMutationResponse, PageInteractionCreateMutationVariables>(
        PageInteractionCreateDocument,
        variables,
      )(),
    options,
  );
usePageInteractionCreateMutation.getKey = () => ['PageInteractionCreate'];
